const images = document.querySelectorAll('.meta-items .meta-items__image');

// Code for image to svg conversion
if (images.length > 0) {
    images.forEach(image => {
        fetch(image.src)
            .then(
                res => res.text())
            .then(data => {
                const parser = new DOMParser();
                const svg = parser.parseFromString(data, 'image/svg+xml').querySelector('svg');

                if (image.id) svg.id = image.id;
                if (image.className) svg.classList = image.classList;

                image.parentNode.replaceChild(svg, image);
            })
            .catch(error => console.error(error))
    });
}

const header: HTMLElement = document.querySelector('.header');
const toggleMobileNavigationButton: HTMLElement = document.querySelector('.openMobileNavigation');
const mobileNavigation: HTMLElement = document.querySelector('.mobileNavigation');
const toggleMobileSearchButton: HTMLElement = document.querySelector('.openMobileSearch');
const mobileSearch: HTMLElement = document.querySelector('.mobileSearch');
const navigationLevels: NodeListOf<HTMLElement> = document.querySelectorAll('.navigation__level1');
const navigationList: NodeListOf<HTMLElement> = document.querySelectorAll('.navigation__list')

const headerHeight = header?.offsetHeight;
window.addEventListener('scroll', () => {
    if (headerHeight < window.scrollY) {
        if (!header?.classList.contains('header--sticky')) header?.classList.add('header--sticky');
    } else {
        if (header?.classList.contains('header--sticky')) header?.classList.remove('header--sticky');
    }
})

document.addEventListener("click", (event: Event) => {
    const target = event.target as HTMLElement;
    let clickedInside = false;

    navigationLevels.forEach(level => {
        if (level.contains(target) && !target.closest(".navigation__level1-list")) {
            level.classList.toggle("inactive");
            clickedInside = true;
        } else {
            level.classList.remove("inactive");
        }
    });

    if (!clickedInside) {
        navigationLevels.forEach(level => level.classList.remove("inactive"));
    }
});

navigationList.forEach(list => {
    list.addEventListener("mouseenter", () => {
        navigationLevels.forEach(level => level.classList.remove("inactive"));
    });
});

toggleMobileNavigationButton?.addEventListener('click', () => {
    checkState(toggleMobileNavigationButton, 'openMobileNavigation--active');
    checkState(mobileNavigation, 'mobileNavigation--active');

    if (document.querySelector('.openMobileSearch--active')) {
        checkState(toggleMobileSearchButton, 'openMobileSearch--active');
    }

    if (document.querySelector('.mobileSearch--active')) {
        checkState(mobileSearch, 'mobileSearch--active');
    }

    checkState(document.querySelector('body'), 'overflow-hidden');
})

toggleMobileSearchButton?.addEventListener('click', () => {
    checkState(toggleMobileSearchButton, 'openMobileSearch--active');
    checkState(mobileSearch, 'mobileSearch--active');

    if (document.querySelector('.openMobileNavigation--active')) {
        checkState(toggleMobileNavigationButton, 'openMobileNavigation--active');
    }

    if (document.querySelector('.mobileNavigation--active')) {
        checkState(mobileNavigation, 'mobileNavigation--active');
    }

    checkState(document.querySelector('body'), 'overflow-hidden');
})

mobileNavigation.querySelectorAll('.mobileNavigation__item')?.forEach(item => {
    item.querySelector('.mobileNavigation__icon')?.addEventListener('click', (el) => {
        checkState((el.target as HTMLElement), 'mobileNavigation__icon--active');
        checkState((el.target as HTMLElement).closest('.mobileNavigation__item').querySelector('.mobileNavigation__level1'), 'mobileNavigation__level1--active');

        document.querySelectorAll('.mobileNavigation__level2').forEach(el => {
            el.classList.remove('mobileNavigation__level2--active')
        })

        document.querySelectorAll('.mobileNavigation__level1-icon').forEach(el => {
            el.classList.remove('mobileNavigation__level1-icon--active')
        })
    });

    item.querySelectorAll('.mobileNavigation__level1-item')?.forEach(child => {
        child.querySelector('.mobileNavigation__level1-icon')?.addEventListener('click', (el) => {
            checkState((el.target as HTMLElement), 'mobileNavigation__level1-icon--active');
            checkState((el.target as HTMLElement).closest('.mobileNavigation__level1-item').querySelector('.mobileNavigation__level2'), 'mobileNavigation__level2--active');
        })
    });


})


/**
 *
 * Helper function to add and remove active state
 *
 * @param element element to check
 * @param activeClass class to add
 */
function checkState(element: HTMLElement, activeClass: string) {
    if (element.classList.contains(activeClass)) {
        element.classList.remove(activeClass);
    } else {
        document.querySelectorAll('.' + element.classList[0]).forEach(el => {
            el.classList.remove(activeClass);
        });

        element.classList.add(activeClass);
    }
}